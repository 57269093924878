import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useNavigate,
  Routes
} from 'react-router-dom';
const { ENVIRONMENT } = process.env;
const dns =
  ENVIRONMENT === 'production'
    ? 'https://e423b5a8de9a36b95bb0584c486a3316@o116128.ingest.us.sentry.io/4508177073569792'
    : 'https://c30b4b7fc20d9c0315658478ca632fa1@o116128.ingest.us.sentry.io/4508177246126080';

ENVIRONMENT !== 'development'
  ? Sentry.init({
      dsn: dns,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
          useNavigate
        })
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: ENVIRONMENT === 'production' ? 1.0 : 0.0
    })
  : '';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default SentryRoutes;
